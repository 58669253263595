section.page-head{
  background-color: $background-gray-1;
  
  h1{
    font-weight: 700;
    font-size: 30px;
    color: $font-black-1;
  }
}

.text-small{
  font-size: 75%;
}

.text-bigger-1{
  font-size: 108%;
}

.text-bigger-2{
  font-size: 116%;
}

.font-inter{
  font-family: $font-family-inter;
}
.font-nunito{
  font-family: $font-family-nunito;
}

.banner-div{

  .slider{
    height: 82vh;
  }
  .slider-small{
    height: 76vh;
  }

  .slider-nav{
    height: 38vh;
  }
  .slider-nav-small{
    height: 28vh;
  }
  
  img{
    height: 100%;
    object-fit: cover;
  }
}

.hr-h-1{
  height: 1px;
  border: none;
  background-color: $border-gray;
}
.hr-h-2{
  height: 1.5px;
  border: none;
  background-color: $border-gray;
}

.font-weight-500{
  font-weight: 500;
}
.font-weight-800{
  font-weight: 800;
}
.font-weight-900{
  font-weight: 900;
}

.bg-gray-1{
  background-color: $background-gray-1;
}


.font-size-30{
  font-size: 30px;
}

.font-size-28{
  font-size: 28px;
}

.font-size-26{
  font-size: 26px;
}

.font-size-24{
  font-size: 24px;
}

.font-size-22{
  font-size: 22px;
}

.object-fit-cover{
  object-fit: cover;
}

.item-plus-minus-box{
  border: 1px solid $border-gray;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.info-box{
  background-color: $background-gray-1;

  &.white{
    background-color: white;
  }
  &.red{
    background-color: red;
  }
}