section.profile{
  .profile-left{

    background-color: $background-gray-1;

    hr{
      width: 85%;
      height: 2px;
      border: none;
      background-color: $border-gray;
    }
    
    .logout-btn{

      width: 75%;
      color: white;
    }

    .topup-btn{

      width: 75%;
      color: white;
      background-color: green;
    }
  }

  .profile-right{
    .profile-tab-head{

      font-weight: 700;
      color: $tab-colour;
      cursor: pointer;
      border-bottom: 2px solid $tab-colour;

      &.active{
        color: $tab-active-colour;
        transition: all 0.3s;
        border-bottom: 2px solid $tab-active-colour;
      }
    }
    
    .profile-child-section{
      display: none;

      &.orders{
        .item-row{
          border-bottom: 1px solid $border-gray;
        }

        .row-element{
          text-decoration: none;
          color: $tab-active-colour;

          .item-row{
            &:hover{
              background-color: $background-gray-1;
              cursor: pointer;
            }
          }

          &:focus{
            outline: none;
          }
        }

        .order-number{
          font-weight: 700;
          color: $tab-active-colour;
        }
      }

      &.notifications{

        .element{
          border-bottom: 1px solid $border-gray;
        }

        .status{
          color: white;
          display: inline-block;

          // &.out-of-stock{
          //   background-color: #e70909;
          // }
          // &.delayed{
          //   background-color: #d0821c;
          // }
          // &.refund{
          //   background-color: #094aab;
          // }
          // &.additional-payment{
          //   background-color: #506369;
          // }

        }

        .date{
          color: $tab-colour;
        }

        .order-number{
          text-decoration: underline;
          font-weight: 700;
          color: $tab-active-colour;
        }

        .message{
          font-weight: 500;
          color: $tab-active-colour;
        }
      }

      &.addresses{
        hr{
          border: none;
          height: 2px;
          background-color: $border-gray;
        }

        .address{
          font-weight: 500;
        }

        .btn-new-address{
          button{
            font-weight: 500;
          }
        }
      }
    }
  }
}