// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Inter', sans-serif;
$font-size-base: 0.95rem;
$line-height-base: 1.6;

$font-family-inter: "Inter", sans-serif;
$font-family-nunito: "Nunito Sans", sans-serif;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$theme-colour: #1f3d4e;
$add-btn-colour: #6c757d;
$add-btn-colour-hover: #8e979f;
$remove-btn-colour: #e3342f;
$remove-btn-colour-hover: #d0211c;

$error-red: #ff0000;
$success-green: #23C552;

$main-black: #333;
$main-black-hover: #999;

$background-gray-1: #f7f9f9;

$tab-active-colour: #0D0D0D;
$tab-colour: #a4a4a4;

$font-black-1: #202020;
$font-gray-1: #4e4e4e;
$font-gray-2: #777777;

$product-bg-color1: #f8f8f8;

$status-color-completed: #4dbd01;
$status-color-out-of-stock: #e70909;
$status-color-delayed: #d0821c;
$status-color-refund: #094aab;
$status-color-additional-payment: #506369;

$btn-body-colour: #202020; 
$btn-body-colour-hover: #424242;
$btn-body-colour-2: #506369; 
$btn-body-colour-2-hover: #72858B; 

$navbar-small-height: 70px;
$navbar-big-height: 85px;

$sidebar-small-width: 200px;
$sidebar-big-width: 250px;

$border-gray: #d0d0d0;

$section-height-1: 38vh;
$slider-main-height: 82vh;
