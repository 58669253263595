footer.footer{
  background-color: $background-gray-1;


  .logo{
    width: 140px;
    height: 70px;

    @media screen and (min-width: 768px) {
      width: 140px;
      height: 70px;
    }
  }
  .copyright-container{
    border-top: 1px solid $border-gray;
  }
}