.contact-us{

  .info-line{
    display: flex;
    flex-wrap: wrap;
  }

  .info-head{
    color: #0D0D0D;
    @media screen and (min-width:768px) {
      width: 30%;
    }
  }
  .info-body{
    @media screen and (min-width:768px) {
      width: 70%;
    }
  }

  iframe{
    height: 49vh;
  }
}