nav.navbar{
  @media screen and (max-width: 767px) {
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 99;
    padding: 0;
  }
}

main{
  @media screen and (max-width: 767px) {
    // position: fixed;
    padding-top: 90px;
  }
}

.navbar-brand{
  .logo{
    width: 140px;
  }
}

.nav-item{
  
  .nav-icon{
    font-size: 24px;
  }

  &.notification-nav-item{
    display: flex;
    position: relative;

    .notification-mark{
      position: absolute;
      left: 24px;
      svg{
        color: $error-red;
      }
    }
  }
}