.about-us{

  .bg-image{
    width: 100%;
    height: 50vh;
    object-fit: cover;
    position: absolute;
  }
  
  .text-info-box{
    min-height: 75vh;
  }

  @media screen and (max-width:991px) {
    .text-info-box{
      padding-top: 45vh;
    }  
  }

  @media screen and (min-width:992px){
    .bg-image{
      width: 75%;
      right: 0;
      bottom: 0;
    }
  }



  .hori-timeline{
    text-align: center;
    .events {
      border-top: 3px solid $border-gray;
    }
  }

  .hori-timeline .list-inline-item {
    display: inline-block;
    text-align: center;
    width: 10%;
    padding-top: 18px;
  }

  ul.list-inline{
    position: relative;
  }

  .list-inline-item.active{
    p{
      color: $tab-active-colour;
      font-weight: 700;
    }
    &::after{
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      background-color: white;
      border: 4px solid $tab-active-colour;
      top: -10px;
      border-radius: 50%;
      z-index: 1;
      transform: translate(-8px, 0px);
    }
  }
  .list-inline-item::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    background-color: white;
    border: 3px solid $border-gray;
    top: -10px;
    border-radius: 50%;
    z-index: 1;
    transform: translate(-8px, 0px);
  }
}