
h2{
  font-size: 30px;
}

h3{
  font-size: 24px;
}

body{
  background-color: white;
  color: $font-gray-1;
}

.cursor-pointer{
  cursor: pointer;
}

.max-w-30{
  max-width: 30vw;
}

.w-20{
  width: 20%;
}
.w-25{
  width: 25%;
}
.w-30{
  width: 30%;
}
.w-70{
  width: 70%;
}
.w-90{
  width: 90%;
}

.w-35{
  width: 35%;
}
.w-lg-35{
  width: 100%;
  @media screen and (min-width:992px) {
    width: 35%;
  }
}
.w-65{
  width: 65%;
}

.right-5{
  right: 5%;
}

.h-20vh{
  height: 20vh;
}
.h-25vh{
  height: 25vh;
}
.h-30vh{
  height: 30vh;
}

.h-35vh{
  height: 35vh;
}

.btn-theme{
  background-color: $theme-colour;
  color: white;
  transition: all 0.3s;

  &:hover{
    color: white;
    opacity: 0.75;
  }
  
}

.notifications-div{
  position: absolute;
  z-index: 9;
  width: 50%;
  margin-top: 20px;

  .action-icon-close{
    font-size: 18px;
    padding: 5px;
    cursor: pointer;
  }

	.main-alert.success{
		background-color: $success-green;
	}
}

.color-gray-1{
  color: $font-gray-1 !important;

  &.active{
    color: $tab-active-colour !important;
    font-weight: 500;
  }

  &.hover-on{
    &:hover{
      color: $tab-active-colour !important;
    }
  }
}

.color-gray-2{
  color: $font-gray-2;
}

.color-white{
  color: white;
  &:hover{
    color: white;
  }
}

.color-tab-active{
  color: $tab-active-colour;
}
.color-tab{
  color: $tab-colour;
}


.section-pt-2{
  padding-top: 50px;
}

.section-pb-2{
  padding-bottom: 50px;
}

.livewire-error-message{
  color: $error-red;
}
.color-red{
  color: #ff0000;
}

.a-effect-remove{
  text-decoration: none;
  color: inherit;

  &:hover{
    text-decoration: none;
    color: inherit;
  }
}

.status-color{
  &.completed{
    background-color: $status-color-completed;
  }
  &.out-of-stock{
    background-color: $status-color-out-of-stock;
  }
  &.delayed{
    background-color: $status-color-delayed;
  }
  &.refund{
    background-color: $status-color-refund;
  }
  &.additional-payment, &.pending-payment{
    background-color: $status-color-additional-payment;
  }
}

ul.pagination{
  justify-content: center;
  
  svg{
    vertical-align: initial;
  }
  
  .page-number-item, .page-item{
    a, button{
      text-decoration: none;
      color: inherit;

      &:hover{
        color: $tab-active-colour;
        font-weight: 500;
      }
      transition: 0.35s all;
    }
    .page-link{
      padding: 0.5rem 0.5rem;
      &:hover{
        font-weight: 700 !important;
        color: inherit !important;
        background: none;
      }
    }
    &.active{
      font-weight: 700 !important;
      .page-link{
        color: $tab-active-colour !important;
      }
    }
  }
}

.bg-none{
  background: none;
}

.bg-black{
  background-color: $btn-body-colour;

  &.cta{
    &:hover{
      background-color: $btn-body-colour-hover;
    }
  }

  transition: 0.35s all;
}

.bg-btn-gray{
  background-color: $btn-body-colour-2;

  &.cta{
    &:hover{
      background-color: $btn-body-colour-2-hover;
    }
  }
}

input.date-input{
  position: relative;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    cursor: pointer;
  }
}

.hover-opacity-6{
  &:hover{
    opacity: 0.6;
  }
  transition: 0.25s ease-out all;
}

.hover-opacity-9{
  &:hover{
    opacity: 0.9;
  }
  transition: 0.25s ease-out all;
}

.hover-bg-grey{
  &:hover{
    background-color: $border-gray;
  }
}

.hover-color-white{
  &:hover{
    color: white;
  }
}

.transition-35{
  transition: all 0.35s ease;
}

.rounded-25{
  border-radius: 25px;
}

.outofstock{

  &::after{
    content: "OUT OF STOCK";
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
}

.outofstock-odiv{
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
}