section.product-detail{


  .dropdown-select{
    border: 1px solid $border-gray;
    
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // padding-right: 25px;
    // background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=);
    // background-repeat: no-repeat;
    // background-position: 95% 42%;
  }
}