section.login-page{

  .login-box{
    padding: 35px;
    background-color: $background-gray-1;


    .forgot-password{
      color: inherit;
      text-decoration: underline;
    }
  }
}