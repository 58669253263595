.home{

  .slick-dots{
    display: flex;
    justify-content: center;
    padding: 16px 0;
    margin-bottom: 0;
    
    li {
      position: relative;
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
    }

    li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 16px;
      height: 16px;
      // padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
    }
  
    li{
      button:before {
        // content: '•';
        content: '○';
        font-size: 10px;
        line-height: 16px;
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        text-align: center;
        opacity: 1;
        color: black;
        @media screen and (min-width:768px) {
          font-size: 16px;
        }
      }
      
      &.slick-active{

        button:before {
          font-size: 22px;
          content: '•';
          // content: '\A';
          opacity: 1;
        }
      }
    }
  }

}

.home-products{
  padding-top: 50px;

  .tab{
    h6{
      color: $tab-colour;
    }
    .active{
      border-bottom: 2px solid $tab-active-colour;
      color: $tab-active-colour;
    }
  }

  .product{

    .product-item{
      margin-top: 30px;
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      width: 100%;
      @media screen and (min-width:768px){
        width: 20%;
      }

      .img-div{
        height: $section-height-1;

        img{
          object-fit: cover;
        }
      }

      .price{
        color: $tab-active-colour;
      }
    }

    .new{
      border: 2px solid $tab-active-colour;
      font-weight: 700;
      position: absolute;

      left: 10%;
      top: 3.5%;
      color: $tab-active-colour;
      z-index: 9;
    }
  }
}

.home-foot{

  a.left, a.right{
    text-decoration: none;
  }

  .left, .right{
    height: $section-height-1;
    background-position: center;
    background-size: cover;
    
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #000;
      opacity: .65; 
    }

    .text-base-middle{
      color: white;
      z-index: 9;
      // position: relative;
    }
  }
}