.checkout{


  .address{

    &.selected{
      border-top: 2px solid $tab-active-colour;
      border-left: 2px solid $tab-active-colour;
      border-right: 2px solid $tab-active-colour;
    }
  }

  .select-btn{

    &:hover, &.selected{
      background-color: $tab-active-colour;
    }

  }
}